import type { CollectionListItemFragment } from '#graphql-operations'

export type CollectionListItemFragmentWithChildren = CollectionListItemFragment & { children: Map<string, CollectionListItemFragmentWithChildren> }

export function buildTree(collections?: CollectionListItemFragment[] | null) {
  if (collections == null)
    return new Map<string, CollectionListItemFragmentWithChildren>()

  const lookup = new Map<string, CollectionListItemFragmentWithChildren>()
  const rootNodes = new Map<string, CollectionListItemFragmentWithChildren>()

  // Initialize lookup Map with collections
  for (const collection of collections) {
    lookup.set(collection.id, {
      ...collection,
      children: new Map<string, CollectionListItemFragmentWithChildren>(),
    })
  }

  // Build the tree structure
  for (const collection of collections) {
    const node = lookup.get(collection.id)!

    if (collection.parent && collection.parent.name === '__root_collection__') {
      rootNodes.set(collection.id, node)
    }
    else if (collection.parent && lookup.has(collection.parent.id)) {
      const parent = lookup.get(collection.parent.id)!

      if (collection.id !== collection.parent.id) {
        parent.children.set(collection.id, node)
      }
      else {
        console.error(`Circular reference detected in collection with id ${collection.id}`)
      }
    }
  }

  const sortChildrenByPosition = (node: CollectionListItemFragmentWithChildren) => {
    if (node.children.size > 0) {
      // Convert Map to array, sort, and create a new Map
      const sortedChildren = new Map(
        [...node.children.entries()].sort((a, b) => a[1].position - b[1].position),
      )
      node.children = sortedChildren

      // Recursively sort the child nodes
      for (const childNode of node.children.values()) {
        sortChildrenByPosition(childNode)
      }
    }
  }

  // Sort root nodes and their descendants
  const sortedRootNodes = new Map(
    [...rootNodes.entries()].sort((a, b) => a[1].position - b[1].position),
  )
  for (const node of sortedRootNodes.values()) {
    sortChildrenByPosition(node)
  }

  return sortedRootNodes
}

export function useCollections() {
  return useNuxtApp().$collections
}
