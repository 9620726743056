import provideState_raZTqNOODM from "/usr/local/app/node_modules/.pnpm/nuxt-graphql-middleware@4.1.1_@parcel+watcher@2.5.0_@types+node@22.9.0_graphql-tag@2.12.6_gra_t6lyiksttx2xxgyhkemfhm6cla/node_modules/nuxt-graphql-middleware/dist/runtime/plugins/provideState.js";
import revive_payload_client_sRLxF9KbVw from "/usr/local/app/node_modules/.pnpm/nuxt@3.14.159_@parcel+watcher@2.5.0_@types+node@22.9.0_eslint@9.14.0_jiti@2.4.0__ioredis@5.4._aqt4l7izzo6emafvidanzqf4cq/node_modules/nuxt/dist/app/plugins/revive-payload.client.js";
import unhead_onfCkyogj1 from "/usr/local/app/node_modules/.pnpm/nuxt@3.14.159_@parcel+watcher@2.5.0_@types+node@22.9.0_eslint@9.14.0_jiti@2.4.0__ioredis@5.4._aqt4l7izzo6emafvidanzqf4cq/node_modules/nuxt/dist/head/runtime/plugins/unhead.js";
import router_vSLCZBxFzI from "/usr/local/app/node_modules/.pnpm/nuxt@3.14.159_@parcel+watcher@2.5.0_@types+node@22.9.0_eslint@9.14.0_jiti@2.4.0__ioredis@5.4._aqt4l7izzo6emafvidanzqf4cq/node_modules/nuxt/dist/pages/runtime/plugins/router.js";
import _0_siteConfig_d6baMH3n1Q from "/usr/local/app/node_modules/.pnpm/nuxt-site-config@2.2.21_magicast@0.3.5_rollup@4.26.0_vite@5.4.11_@types+node@22.9.0_terser@5._w7hl5sa2k3yh5iegl6d37ovuzy/node_modules/nuxt-site-config/dist/runtime/nuxt/plugins/0.siteConfig.js";
import payload_client_cdm5SRn0kN from "/usr/local/app/node_modules/.pnpm/nuxt@3.14.159_@parcel+watcher@2.5.0_@types+node@22.9.0_eslint@9.14.0_jiti@2.4.0__ioredis@5.4._aqt4l7izzo6emafvidanzqf4cq/node_modules/nuxt/dist/app/plugins/payload.client.js";
import navigation_repaint_client_0tpsHjXAJV from "/usr/local/app/node_modules/.pnpm/nuxt@3.14.159_@parcel+watcher@2.5.0_@types+node@22.9.0_eslint@9.14.0_jiti@2.4.0__ioredis@5.4._aqt4l7izzo6emafvidanzqf4cq/node_modules/nuxt/dist/app/plugins/navigation-repaint.client.js";
import check_outdated_build_client_vVhEvYA17Y from "/usr/local/app/node_modules/.pnpm/nuxt@3.14.159_@parcel+watcher@2.5.0_@types+node@22.9.0_eslint@9.14.0_jiti@2.4.0__ioredis@5.4._aqt4l7izzo6emafvidanzqf4cq/node_modules/nuxt/dist/app/plugins/check-outdated-build.client.js";
import chunk_reload_client_l2FlCcRvJQ from "/usr/local/app/node_modules/.pnpm/nuxt@3.14.159_@parcel+watcher@2.5.0_@types+node@22.9.0_eslint@9.14.0_jiti@2.4.0__ioredis@5.4._aqt4l7izzo6emafvidanzqf4cq/node_modules/nuxt/dist/app/plugins/chunk-reload.client.js";
import components_plugin_KR1HBZs4kY from "/usr/local/app/.nuxt/components.plugin.mjs";
import prefetch_client_CbVE7opxSs from "/usr/local/app/node_modules/.pnpm/nuxt@3.14.159_@parcel+watcher@2.5.0_@types+node@22.9.0_eslint@9.14.0_jiti@2.4.0__ioredis@5.4._aqt4l7izzo6emafvidanzqf4cq/node_modules/nuxt/dist/pages/runtime/plugins/prefetch.client.js";
import plugin_client_Ym2boIUqUF from "/usr/local/app/node_modules/.pnpm/@nuxtjs+color-mode@3.5.2_magicast@0.3.5_rollup@4.26.0/node_modules/@nuxtjs/color-mode/dist/runtime/plugin.client.js";
import documentDriven_0OXmFmpzaA from "/usr/local/app/node_modules/.pnpm/@nuxt+content@2.13.4_ioredis@5.4.1_magicast@0.3.5_nuxt@3.14.159_@parcel+watcher@2.5.0_@types+_cpzcyp4wpvdem5gzxjlmtendxy/node_modules/@nuxt/content/dist/runtime/legacy/plugins/documentDriven.js";
import nuxt_plugin_wvTWgHVXIt from "/usr/local/app/node_modules/.pnpm/nuxt-delay-hydration@1.3.8_magicast@0.3.5_rollup@4.26.0/node_modules/nuxt-delay-hydration/dist/runtime/nuxt-plugin.js";
import plugin_m40Wni9L2K from "/usr/local/app/node_modules/.pnpm/v-wave@3.0.2_magicast@0.3.5_rollup@4.26.0_vue@3.5.12_typescript@5.6.3_/node_modules/v-wave/nuxt/runtime/plugin.ts";
import plugin_wy0B721ODc from "/usr/local/app/node_modules/.pnpm/@nuxtjs+device@3.2.4/node_modules/@nuxtjs/device/dist/runtime/plugin.js";
import switch_locale_path_ssr_NDi5qZQCM8 from "/usr/local/app/node_modules/.pnpm/@nuxtjs+i18n@8.5.5_magicast@0.3.5_rollup@4.26.0_vue@3.5.12_typescript@5.6.3_/node_modules/@nuxtjs/i18n/dist/runtime/plugins/switch-locale-path-ssr.js";
import i18n_3kGK9yUjA3 from "/usr/local/app/node_modules/.pnpm/@nuxtjs+i18n@8.5.5_magicast@0.3.5_rollup@4.26.0_vue@3.5.12_typescript@5.6.3_/node_modules/@nuxtjs/i18n/dist/runtime/plugins/i18n.js";
import plugin_UXG9JrcSmh from "/usr/local/app/node_modules/.pnpm/@nuxt+image@1.8.1_ioredis@5.4.1_magicast@0.3.5_rollup@4.26.0/node_modules/@nuxt/image/dist/runtime/plugin.js";
import unocss_MzCDxu9LMj from "/usr/local/app/.nuxt/unocss.mjs";
import plugin_xDtleEcACi from "/usr/local/app/modules/gtm/runtime/plugin.ts";
import _0_graphqlConfig_yaUZblG674 from "/usr/local/app/plugins/0.graphqlConfig.ts";
import _1_auth_slaK5pfDbE from "/usr/local/app/plugins/1.auth.ts";
import _2_activeOrder_yYINElYs5H from "/usr/local/app/plugins/2.activeOrder.ts";
import collections_pRcEP3swaI from "/usr/local/app/plugins/collections.ts";
import foxentry_client_gcy3vwc779 from "/usr/local/app/plugins/foxentry.client.ts";
import scroll_client_HJUo2UKx9n from "/usr/local/app/plugins/scroll.client.ts";
import supportAgent_3y6YttNG9k from "/usr/local/app/plugins/supportAgent.ts";
import vee_validate_KcKlKmvCrJ from "/usr/local/app/plugins/vee-validate.ts";
export default [
  provideState_raZTqNOODM,
  revive_payload_client_sRLxF9KbVw,
  unhead_onfCkyogj1,
  router_vSLCZBxFzI,
  _0_siteConfig_d6baMH3n1Q,
  payload_client_cdm5SRn0kN,
  navigation_repaint_client_0tpsHjXAJV,
  check_outdated_build_client_vVhEvYA17Y,
  chunk_reload_client_l2FlCcRvJQ,
  components_plugin_KR1HBZs4kY,
  prefetch_client_CbVE7opxSs,
  plugin_client_Ym2boIUqUF,
  documentDriven_0OXmFmpzaA,
  nuxt_plugin_wvTWgHVXIt,
  plugin_m40Wni9L2K,
  plugin_wy0B721ODc,
  switch_locale_path_ssr_NDi5qZQCM8,
  i18n_3kGK9yUjA3,
  plugin_UXG9JrcSmh,
  unocss_MzCDxu9LMj,
  plugin_xDtleEcACi,
  _0_graphqlConfig_yaUZblG674,
  _1_auth_slaK5pfDbE,
  _2_activeOrder_yYINElYs5H,
  collections_pRcEP3swaI,
  foxentry_client_gcy3vwc779,
  scroll_client_HJUo2UKx9n,
  supportAgent_3y6YttNG9k,
  vee_validate_KcKlKmvCrJ
]