import { default as indexZRmv4rwJvqMeta } from "/usr/local/app/pages/index.vue?macro=true";
import { default as _91_46_46_46slug_93ehlUViFFkaMeta } from "/usr/local/app/pages/[...slug].vue?macro=true";
import { default as indexbcW1kPi65kMeta } from "/usr/local/app/pages/account/addresses/index.vue?macro=true";
import { default as _91id_93eKVSoMbmknMeta } from "/usr/local/app/pages/account/disputes/[id].vue?macro=true";
import { default as indexmupPhdpzHJMeta } from "/usr/local/app/pages/account/disputes/index.vue?macro=true";
import { default as indexf0kufjSeQTMeta } from "/usr/local/app/pages/account/index.vue?macro=true";
import { default as _91code_93Z5gRwhAl1xMeta } from "/usr/local/app/pages/account/orders/[code].vue?macro=true";
import { default as indexyavSc54tHJMeta } from "/usr/local/app/pages/account/orders/index.vue?macro=true";
import { default as securityGIscjYEtcLMeta } from "/usr/local/app/pages/account/security.vue?macro=true";
import { default as accountkzLoSvJwtmMeta } from "/usr/local/app/pages/account.vue?macro=true";
import { default as change_45emailUR6mw1en5xMeta } from "/usr/local/app/pages/auth/change-email.vue?macro=true";
import { default as loginF3gIq4xjsbMeta } from "/usr/local/app/pages/auth/login.vue?macro=true";
import { default as logoutgfTlJJpOwXMeta } from "/usr/local/app/pages/auth/logout.vue?macro=true";
import { default as new_45passwordHKAqxDljR1Meta } from "/usr/local/app/pages/auth/new-password.vue?macro=true";
import { default as registerD1YI4k4ySdMeta } from "/usr/local/app/pages/auth/register.vue?macro=true";
import { default as reset_45passwordjZT5RCzmJ4Meta } from "/usr/local/app/pages/auth/reset-password.vue?macro=true";
import { default as verify_45emailShAT883FSKMeta } from "/usr/local/app/pages/auth/verify-email.vue?macro=true";
import { default as _91code_93n8bQXXLUreMeta } from "/usr/local/app/pages/checkout/confirmation/[code].vue?macro=true";
import { default as indexS4P2YxqBHcMeta } from "/usr/local/app/pages/checkout/index.vue?macro=true";
import { default as _91slug_939CT60eHGEsMeta } from "/usr/local/app/pages/collections/[id]/[slug].vue?macro=true";
import { default as best_45sellersm0l9OPhkuGMeta } from "/usr/local/app/pages/collections/best-sellers.vue?macro=true";
import { default as new_45arrivalsOthMm8zd5xMeta } from "/usr/local/app/pages/collections/new-arrivals.vue?macro=true";
import { default as _91_91slug_93_939lFgEk0npoMeta } from "/usr/local/app/pages/products/[id]/[[slug]].vue?macro=true";
import { default as searchYhxkQS4hULMeta } from "/usr/local/app/pages/search.vue?macro=true";
import { default as _91slug_93Wy0ZMWlk3DMeta } from "/usr/local/app/pages/stores/[id]/[slug].vue?macro=true";
export default [
  {
    name: "index",
    path: "/",
    component: () => import("/usr/local/app/pages/index.vue")
  },
  {
    name: "slug___cs",
    path: "/cs/:slug(.*)*",
    meta: _91_46_46_46slug_93ehlUViFFkaMeta || {},
    component: () => import("/usr/local/app/pages/[...slug].vue")
  },
  {
    name: "slug___pl",
    path: "/pl/:slug(.*)*",
    meta: _91_46_46_46slug_93ehlUViFFkaMeta || {},
    component: () => import("/usr/local/app/pages/[...slug].vue")
  },
  {
    name: accountkzLoSvJwtmMeta?.name,
    path: "/cs/account",
    meta: accountkzLoSvJwtmMeta || {},
    component: () => import("/usr/local/app/pages/account.vue"),
    children: [
  {
    name: "account-addresses___cs",
    path: "addresses",
    component: () => import("/usr/local/app/pages/account/addresses/index.vue")
  },
  {
    name: "account-disputes-id___cs",
    path: "disputes/:id()",
    component: () => import("/usr/local/app/pages/account/disputes/[id].vue")
  },
  {
    name: "account-disputes___cs",
    path: "disputes",
    meta: indexmupPhdpzHJMeta || {},
    component: () => import("/usr/local/app/pages/account/disputes/index.vue")
  },
  {
    name: "account___cs",
    path: "",
    component: () => import("/usr/local/app/pages/account/index.vue")
  },
  {
    name: "account-orders-code___cs",
    path: "orders/:code()",
    component: () => import("/usr/local/app/pages/account/orders/[code].vue")
  },
  {
    name: "account-orders___cs",
    path: "orders",
    meta: indexyavSc54tHJMeta || {},
    component: () => import("/usr/local/app/pages/account/orders/index.vue")
  },
  {
    name: "account-security___cs",
    path: "security",
    component: () => import("/usr/local/app/pages/account/security.vue")
  }
]
  },
  {
    name: accountkzLoSvJwtmMeta?.name,
    path: "/pl/account",
    meta: accountkzLoSvJwtmMeta || {},
    component: () => import("/usr/local/app/pages/account.vue"),
    children: [
  {
    name: "account-addresses___pl",
    path: "addresses",
    component: () => import("/usr/local/app/pages/account/addresses/index.vue")
  },
  {
    name: "account-disputes-id___pl",
    path: "disputes/:id()",
    component: () => import("/usr/local/app/pages/account/disputes/[id].vue")
  },
  {
    name: "account-disputes___pl",
    path: "disputes",
    meta: indexmupPhdpzHJMeta || {},
    component: () => import("/usr/local/app/pages/account/disputes/index.vue")
  },
  {
    name: "account___pl",
    path: "",
    component: () => import("/usr/local/app/pages/account/index.vue")
  },
  {
    name: "account-orders-code___pl",
    path: "orders/:code()",
    component: () => import("/usr/local/app/pages/account/orders/[code].vue")
  },
  {
    name: "account-orders___pl",
    path: "orders",
    meta: indexyavSc54tHJMeta || {},
    component: () => import("/usr/local/app/pages/account/orders/index.vue")
  },
  {
    name: "account-security___pl",
    path: "security",
    component: () => import("/usr/local/app/pages/account/security.vue")
  }
]
  },
  {
    name: "auth-change-email___cs",
    path: "/cs/auth/change-email",
    component: () => import("/usr/local/app/pages/auth/change-email.vue")
  },
  {
    name: "auth-change-email___pl",
    path: "/pl/auth/change-email",
    component: () => import("/usr/local/app/pages/auth/change-email.vue")
  },
  {
    name: "auth-login___cs",
    path: "/cs/auth/login",
    component: () => import("/usr/local/app/pages/auth/login.vue")
  },
  {
    name: "auth-login___pl",
    path: "/pl/auth/login",
    component: () => import("/usr/local/app/pages/auth/login.vue")
  },
  {
    name: "auth-logout___cs",
    path: "/cs/auth/logout",
    component: () => import("/usr/local/app/pages/auth/logout.vue")
  },
  {
    name: "auth-logout___pl",
    path: "/pl/auth/logout",
    component: () => import("/usr/local/app/pages/auth/logout.vue")
  },
  {
    name: "auth-new-password___cs",
    path: "/cs/auth/new-password",
    component: () => import("/usr/local/app/pages/auth/new-password.vue")
  },
  {
    name: "auth-new-password___pl",
    path: "/pl/auth/new-password",
    component: () => import("/usr/local/app/pages/auth/new-password.vue")
  },
  {
    name: "auth-register___cs",
    path: "/cs/auth/register",
    component: () => import("/usr/local/app/pages/auth/register.vue")
  },
  {
    name: "auth-register___pl",
    path: "/pl/auth/register",
    component: () => import("/usr/local/app/pages/auth/register.vue")
  },
  {
    name: "auth-reset-password___cs",
    path: "/cs/auth/reset-password",
    component: () => import("/usr/local/app/pages/auth/reset-password.vue")
  },
  {
    name: "auth-reset-password___pl",
    path: "/pl/auth/reset-password",
    component: () => import("/usr/local/app/pages/auth/reset-password.vue")
  },
  {
    name: "auth-verify-email___cs",
    path: "/cs/auth/verify-email",
    component: () => import("/usr/local/app/pages/auth/verify-email.vue")
  },
  {
    name: "auth-verify-email___pl",
    path: "/pl/auth/verify-email",
    component: () => import("/usr/local/app/pages/auth/verify-email.vue")
  },
  {
    name: "checkout-confirmation-code___cs",
    path: "/cs/checkout/confirmation/:code()",
    component: () => import("/usr/local/app/pages/checkout/confirmation/[code].vue")
  },
  {
    name: "checkout-confirmation-code___pl",
    path: "/pl/checkout/confirmation/:code()",
    component: () => import("/usr/local/app/pages/checkout/confirmation/[code].vue")
  },
  {
    name: "checkout___cs",
    path: "/cs/checkout",
    meta: indexS4P2YxqBHcMeta || {},
    component: () => import("/usr/local/app/pages/checkout/index.vue")
  },
  {
    name: "checkout___pl",
    path: "/pl/checkout",
    meta: indexS4P2YxqBHcMeta || {},
    component: () => import("/usr/local/app/pages/checkout/index.vue")
  },
  {
    name: "collections-id-slug___cs",
    path: "/cs/collections/:id()/:slug()",
    component: () => import("/usr/local/app/pages/collections/[id]/[slug].vue")
  },
  {
    name: "collections-id-slug___pl",
    path: "/pl/collections/:id()/:slug()",
    component: () => import("/usr/local/app/pages/collections/[id]/[slug].vue")
  },
  {
    name: "collections-best-sellers___cs",
    path: "/cs/collections/best-sellers",
    component: () => import("/usr/local/app/pages/collections/best-sellers.vue")
  },
  {
    name: "collections-best-sellers___pl",
    path: "/pl/collections/best-sellers",
    component: () => import("/usr/local/app/pages/collections/best-sellers.vue")
  },
  {
    name: "collections-new-arrivals___cs",
    path: "/cs/collections/new-arrivals",
    component: () => import("/usr/local/app/pages/collections/new-arrivals.vue")
  },
  {
    name: "collections-new-arrivals___pl",
    path: "/pl/collections/new-arrivals",
    component: () => import("/usr/local/app/pages/collections/new-arrivals.vue")
  },
  {
    name: "index___cs",
    path: "/cs",
    component: () => import("/usr/local/app/pages/index.vue")
  },
  {
    name: "index___pl",
    path: "/pl",
    component: () => import("/usr/local/app/pages/index.vue")
  },
  {
    name: "products-id-slug___cs",
    path: "/cs/products/:id()/:slug?",
    meta: _91_91slug_93_939lFgEk0npoMeta || {},
    component: () => import("/usr/local/app/pages/products/[id]/[[slug]].vue")
  },
  {
    name: "products-id-slug___pl",
    path: "/pl/products/:id()/:slug?",
    meta: _91_91slug_93_939lFgEk0npoMeta || {},
    component: () => import("/usr/local/app/pages/products/[id]/[[slug]].vue")
  },
  {
    name: "search___cs",
    path: "/cs/search",
    component: () => import("/usr/local/app/pages/search.vue")
  },
  {
    name: "search___pl",
    path: "/pl/search",
    component: () => import("/usr/local/app/pages/search.vue")
  },
  {
    name: "stores-id-slug___cs",
    path: "/cs/stores/:id()/:slug()",
    component: () => import("/usr/local/app/pages/stores/[id]/[slug].vue")
  },
  {
    name: "stores-id-slug___pl",
    path: "/pl/stores/:id()/:slug()",
    component: () => import("/usr/local/app/pages/stores/[id]/[slug].vue")
  }
]