
import * as imgproxyRuntime$7ZpiqxJWcO from '/usr/local/app/app/imgproxy.ts'

export const imageOptions = {
  "screens": {
    "xs": 320,
    "sm": 640,
    "md": 768,
    "lg": 1024,
    "xl": 1280,
    "xxl": 1536,
    "2xl": 1536
  },
  "presets": {},
  "provider": "imgproxy",
  "domains": [
    "localhost:3000",
    "fashova.com",
    "staging.fashova.com",
    "vendure.uzo.io",
    "storefront-staging.fashova.com",
    "storefront-production.fashova.com",
    "storefront-stg.fashova.com",
    "storefront-prod.fashova.com",
    "staging.vendure.uzo.io",
    "vendure.staging.uzo.io"
  ],
  "alias": {},
  "densities": [
    1,
    2,
    1,
    2
  ],
  "format": [
    "avif",
    "webp",
    "webp"
  ]
}

imageOptions.providers = {
  ['imgproxy']: { provider: imgproxyRuntime$7ZpiqxJWcO, defaults: {"imgproxyUrl":""} }
}
        